import React from 'react';

import GDPR from '../components/gdpr';


class Required extends React.Component{

    render(){
        return(
            <GDPR>

            </GDPR>
        )
    }
}

export default Required;