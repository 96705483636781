import React from 'react';
import footerStyle from '../components/footer.module.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Required from './required';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <footer className={footerStyle.footer}>
    <section className={footerStyle.subfooter}>
      <div className={footerStyle.back_to_top}>
        <AnchorLink offset="100" href="#top">
          Back to top
        </AnchorLink>
      </div>
    </section>
    <section className={footerStyle.navigation}>
      <div className={footerStyle.container}>
        <div className={footerStyle.nav_left}>
          <ul>
            <li>
              <AniLink to="/mentions-legales/">Mentions légales</AniLink>
            </li>
            <li>
              <AniLink to="/politique-de-confidentialite/">Politique de confidentialité</AniLink>
            </li>
            <li>
              <AniLink to="/contact/">Nous contacter</AniLink>
            </li>
            <li>
              <AniLink to="/jobs/">Jobs</AniLink>
            </li>
            {/* <li>
              <AniLink to="/contact/">Contact</AniLink>
            </li> */}
          </ul>
          <ul>
            <li>MDS Digital Agency</li>
            <li>
              <a
                href="https://goo.gl/maps/HifC8B5WMt9qKw259"
                target="_blank"
                rel="noopener noreferrer"
              >
                Boulevard Initialis, 7/1 7000 Mons
              </a>
            </li>
            <li>
              <a href="tel:+32493418382">Tel: +32 493 41 83 82</a>
            </li>
            <li>TVA: BE 0538.820.152</li>
          </ul>
        </div>
        <div className={footerStyle.nav_right}>
          <div className={footerStyle.follow}>Suivez-nous</div>
          <div className={footerStyle.social}>
            <a
              className={footerStyle.facebook}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/mdsdigitalagency/"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              className={footerStyle.instagram}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/mds_digitalagency/"
            >
              <FontAwesomeIcon className={footerStyle.ico_insta} icon={faInstagram} />
            </a>
            <a
              className={footerStyle.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/mds-digitalagency"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    </section>
    <Required />
  </footer>
);
