import React from 'react';
import gdprStyle from '../components/gdpr.module.css' 
import { Link } from 'gatsby';

class GDPR extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        }
        this.toggle=this.toggle.bind(this)
        this.acceptCookie=this.acceptCookie.bind(this);
    }
    toggle(){
        this.setState({isOpen: !this.state.isOpen});
        
    }

    setCookie(cname, cvalue, exdays){
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }


      checkCookie() {
        var policy = this.getCookie("cpolicy");
        if (policy === "" | policy === null ) {
          this.setState({isOpen: true})
        } 
      } 

      acceptCookie(){
        this.setCookie('cpolicy', 'true', 365)
        this.setCookie('caccepted', 'true', 365)
          console.log('accepted');
          this.setState({isOpen: false})
      }

      componentDidMount(){
        var policy = this.getCookie("cpolicy");
        if (policy === "" | policy === null ) {
            this.setState({isOpen: true})
            this.setCookie('cpolicy', 'false', 365)
            this.setCookie('caccepted', 'false', 365)

        }else if(this.getCookie('cpolicy') === 'false'){
            this.setState({isOpen: true})
            console.log('state:' + this.state)

        }else{
            this.setState({isOpen: false});
        }
      }

      render(){
          return(
              <div className={[gdprStyle.background , this.state.isOpen ? gdprStyle.open : gdprStyle.close].join(" ")} >
                <div className={gdprStyle.container}>
                    <p>
                    MDS Digital Agency utilise des cookies sur ce site. Avec votre consentement, nous les utiliserons pour mesurer et analyser l'utilisation du site (cookies analytiques). Pour plus d'informations, veuillez lire la <Link to="/vie-privee/">politique de confidentialité</Link>.</p><p> Personnaliser mes <Link to="/cookies/">préférences de cookies.</Link>
                    </p>
                    <div style={{ textAlign: 'center'}} >
                        <span className={gdprStyle.cta} onClick={this.acceptCookie}>Accepter</span>
                    </div>
                </div>

              </div>
          )
      }
    
}
  export default GDPR;