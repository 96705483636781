import React from 'react';
import hamburgerStyle from './hamburger.module.css';
import '../assets/css/fonts.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import hamburger from './menu.module.css';
import icon_website from '../assets/img/vectors/SVG/website.svg';
import icon_smartphone from '../assets/img/vectors/SVG/smartphone.svg';
import icon_megaphone from '../assets/img/vectors/SVG/megaphone.svg';
import icon_newsletter from '../assets/img/vectors/SVG/mail.svg';

class Hamburger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownIsOpen: false
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  toggleDropdown() {
    this.setState({ dropdownIsOpen: !this.state.dropdownIsOpen });
    // if(this.state.dropdownIsOpen){
    //     this.setState({dropdownIsOpen : false})
    // }else{
    //     this.setState({dropdownIsOpen: true})
    // }
  }

  render() {
    return (
      <div>
        {/* <button className={hamburgerStyle.button} onClick = { () => this.setState({isOpen: !this.state.isOpen})}>test</button> */}
        <button
          className={hamburgerStyle.button}
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <div
            className={[
              hamburgerStyle.lines,
              this.state.isOpen ? hamburgerStyle.lines_open : hamburgerStyle.lines_close
            ].join(' ')}
          >
            <span className={hamburgerStyle.line}> </span>
            <span className={hamburgerStyle.line}> </span>
            <span className={hamburgerStyle.line}> </span>
          </div>
        </button>
        <div
          className={[
            hamburgerStyle.modal,
            this.state.isOpen ? hamburgerStyle.open : hamburgerStyle.close
          ].join(' ')}
        >
          <ul className={hamburgerStyle.nav}>
            <li className={hamburgerStyle.cta}>
              <AniLink cover bg="#fba515" to="/contact/">
                Parlons de votre projet
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                bg="#fba515"
                to="/"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              >
                Accueil
              </AniLink>
            </li>
            <li>
              <span onClick={this.toggleDropdown}>Services</span>
              <ul
                className={[
                  hamburgerStyle.dropdown_container,
                  this.state.dropdownIsOpen
                    ? hamburgerStyle.dropdown_open
                    : hamburgerStyle.dropdown_close
                ].join(' ')}
              >
                <li style={{ marginTop: '30px' }}>
                  <AniLink
                    cover
                    bg="#fba515"
                    to="/services/siteweb/"
                    className={hamburgerStyle.menu_header}
                  >
                    <img src={icon_website} className={hamburger.menu_icon} alt="Icône site web" />
                    <span
                      className={hamburger.menu_header_yellow}
                      style={{
                        fontFamily: `"Lato", sans-serif`,

                        fontWeight: 400,
                        fontSize: '1.7em',
                        lineHeight: '20px',
                        color: '#fba515'
                      }}
                    >
                      Site web
                    </span>
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/website/creation-site-web/">
                    Création
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/website/referencement/">
                    Référencement
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/website/gestion-de-contenu/">
                    Gestion de contenu
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/website/optimisation">
                    Optimisation
                  </AniLink>
                </li>
                <li style={{ marginTop: '30px' }}>
                  <AniLink
                    cover
                    bg="#fba515"
                    to="/services/reseaux-sociaux/"
                    className={hamburgerStyle.menu_header}
                  >
                    <img
                      src={icon_megaphone}
                      className={hamburger.menu_icon}
                      alt="Icône site web"
                    />
                    <span
                      className={hamburger.menu_header_yellow}
                      style={{
                        fontFamily: `"Lato", sans-serif`,

                        fontWeight: 400,
                        fontSize: '1.7em',
                        lineHeight: '20px',
                        color: '#2291ca'
                      }}
                    >
                      Réseaux sociaux
                    </span>
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/reseaux-sociaux/social-branding/">
                    Social Branding
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/reseaux-sociaux/social-ads/">
                    Social Ads
                  </AniLink>
                </li>
                <li>
                  <AniLink cover bg="#fba515" to="/services/reseaux-sociaux/remarketing/">
                    Remarketing
                  </AniLink>
                </li>

                <li style={{ marginTop: '30px' }}>
                  <AniLink
                    cover
                    bg="#fba515"
                    to="/services/application-mobile/"
                    className={hamburgerStyle.menu_header}
                  >
                    <img
                      src={icon_smartphone}
                      className={hamburger.menu_icon}
                      alt="Icône site web"
                    />
                    <span
                      className={hamburger.menu_header_yellow}
                      style={{
                        fontFamily: `"Lato", sans-serif`,

                        fontWeight: 400,
                        fontSize: '1.7em',
                        lineHeight: '20px',
                        color: '#e21b39'
                      }}
                    >
                      Application mobile
                    </span>
                  </AniLink>
                </li>
                <li style={{ marginTop: '30px' }}>
                  <AniLink
                    cover
                    bg="#fba515"
                    to="/services/newsletter/"
                    className={hamburgerStyle.menu_header}
                  >
                    <img
                      src={icon_newsletter}
                      className={hamburger.menu_icon}
                      alt="Icône site web"
                    />
                    <span
                      className={hamburger.menu_header_yellow}
                      style={{
                        fontFamily: `"Lato", sans-serif`,

                        fontWeight: 400,
                        fontSize: '1.7em',
                        lineHeight: '20px',
                        color: '#6c307e'
                      }}
                    >
                      Newsletter
                    </span>
                  </AniLink>
                </li>
              </ul>
            </li>
            <li>
              <AniLink
                cover
                bg="#fba515"
                to="/realisations/"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              >
                Réalisations
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                bg="#fba515"
                to="/references/"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              >
                Références
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                bg="#fba515"
                to="/a-propos/"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              >
                À propos
              </AniLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Hamburger;
