import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import menuStyle from './menu.module.css';
import logo from '../assets/img/logo_mds_title.svg';
import logo_white from '../assets/img/logo_mds_title_white.svg';
import icon_website from '../assets/img/vectors/SVG/website.svg';
import icon_smartphone from '../assets/img/vectors/SVG/smartphone.svg';
import icon_megaphone from '../assets/img/vectors/SVG/megaphone.svg';
import icon_newsletter from '../assets/img/vectors/SVG/mail.svg';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      isMobile: true,
    };
    this.checkWidth = this.checkWidth.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });

    this.checkWidth();

    document.addEventListener('resize', () => {
      this.checkWidth();
    });
  }

  checkWidth() {
    this.setState({
      isMobile: document.body.clientWidth < 1119,
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  render() {
    return (
      <div className={menuStyle.row}>
        <div className={this.state.isTop ? menuStyle.unscrolled : menuStyle.scrolled}>
          <div className={menuStyle.container} id="menu">
            <div className={menuStyle.logo}>
              <AniLink cover bg="#fba515" to="/">
                <img alt="Logo MDS" src={this.state.isTop ? logo_white : logo} />
              </AniLink>
            </div>
            <ul className={menuStyle.link_list}>
              <li>
                <AniLink cover bg="#fba515" className={menuStyle.link_item} to="/">
                  Accueil
                </AniLink>
              </li>
              <li className={menuStyle.menu_services}>
                <AniLink cover bg="#fba515" className={menuStyle.link_item} to="#">
                  Services
                </AniLink>
                {!this.state.isMobile && (
                  <div className={menuStyle.dropdown_container}>
                    <div className={menuStyle.dropdown_arrow} />
                    <div className={menuStyle.dropdown_menu}>
                      <ul>
                        <li>
                          <AniLink
                            cover
                            bg="#fba515"
                            to="/services/siteweb/"
                            className={menuStyle.menu_header}
                          >
                            <img
                              src={icon_website}
                              className={menuStyle.menu_icon}
                              alt="Icône site web"
                            />
                            <span className={menuStyle.menu_header_yellow}>Site web</span>
                          </AniLink>
                        </li>
                        <ul>
                          <li>
                            <AniLink cover bg="#fba515" to="/services/website/creation-site-web/">
                              Création
                            </AniLink>
                          </li>
                          <li>
                            <AniLink cover bg="#fba515" to="/services/website/referencement/">
                              Référencement
                            </AniLink>
                          </li>
                          <li>
                            <AniLink cover bg="#fba515" to="/services/website/gestion-de-contenu/">
                              Gestion de contenu
                            </AniLink>
                          </li>
                          <li>
                            <AniLink cover bg="#fba515" to="/services/website/optimisation">
                              Optimisation
                            </AniLink>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <AniLink
                            cover
                            bg="#fba515"
                            to="/services/reseaux-sociaux/"
                            className={menuStyle.menu_header}
                          >
                            <img
                              src={icon_megaphone}
                              className={menuStyle.menu_icon}
                              alt="Icône réseaux sociaux"
                            />
                            <span className={menuStyle.menu_header_blue}>Réseaux sociaux</span>
                          </AniLink>
                        </li>
                        <ul>
                          <li>
                            <AniLink
                              className={menuStyle.link_blue}
                              cover
                              bg="#fba515"
                              to="/services/reseaux-sociaux/social-branding/"
                            >
                              Social Branding
                            </AniLink>
                          </li>
                          <li>
                            <AniLink
                              className={menuStyle.link_blue}
                              cover
                              bg="#fba515"
                              to="/services/reseaux-sociaux/social-ads/"
                            >
                              Social Ads
                            </AniLink>
                          </li>
                          <li>
                            <AniLink
                              className={menuStyle.link_blue}
                              cover
                              bg="#fba515"
                              to="/services/reseaux-sociaux/remarketing/"
                            >
                              Remarketing
                            </AniLink>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <AniLink
                            cover
                            bg="#fba515"
                            to="/services/application-mobile/"
                            className={menuStyle.menu_header}
                          >
                            <img
                              src={icon_smartphone}
                              className={menuStyle.menu_icon}
                              alt="Icône app"
                            />
                            <span className={menuStyle.menu_header_red}>Application mobile</span>
                          </AniLink>
                        </li>
                        <li>
                          <AniLink
                            cover
                            bg="#fba515"
                            to="/services/newsletter/"
                            className={menuStyle.menu_header}
                          >
                            <img
                              src={icon_newsletter}
                              className={menuStyle.menu_icon}
                              alt="Icône newsletter"
                            />
                            <span className={menuStyle.menu_header_purple}>Newsletter</span>
                          </AniLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>

              <li>
                <AniLink cover bg="#fba515" className={menuStyle.link_item} to="/realisations/">
                  Réalisations
                </AniLink>
              </li>
              <li>
                <AniLink cover bg="#fba515" className={menuStyle.link_item} to="/references/">
                  Références
                </AniLink>
              </li>
              <li>
                <AniLink cover bg="#fba515" className={menuStyle.link_item} to="/a-propos/">
                  À propos
                </AniLink>
              </li>
            </ul>

            <div className={menuStyle.contact}>
              <AniLink cover bg="#fba515" className={menuStyle.cta_primary} to="/contact/">
                Parlons de votre projet
              </AniLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Menu;
